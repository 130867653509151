import { createHash } from "crypto";
import { join } from "path";

import type { IPitcherClient } from "@codesandbox/pitcher-client";
import { Barrier, Emitter } from "@codesandbox/pitcher-client";
import { PitcherBrowserClient } from "@codesandbox/pitcher-client/dist/esm/browser";
import type { IDisposable } from "@codesandbox/pitcher-common";
import {
  DisposableStore,
  nullthrows,
  sleep,
} from "@codesandbox/pitcher-common";
import getAccessibilityServiceOverride from "@codingame/monaco-vscode-accessibility-service-override";
import getAuthenticationServiceOverride from "@codingame/monaco-vscode-authentication-service-override";
import getBulkEditServiceOverride from "@codingame/monaco-vscode-bulk-edit-service-override";
import getChatServiceOverride from "@codingame/monaco-vscode-chat-service-override";
import getCommentsServiceOverride from "@codingame/monaco-vscode-comments-service-override";
import getConfigurationServiceOverride from "@codingame/monaco-vscode-configuration-service-override";
import getDialogsServiceOverride from "@codingame/monaco-vscode-dialogs-service-override";
import getEmmetServiceOverride from "@codingame/monaco-vscode-emmet-service-override";
import getEnvironmentServiceOverride from "@codingame/monaco-vscode-environment-service-override";
import getExplorerServiceOverride from "@codingame/monaco-vscode-explorer-service-override";
import getExtensionGalleryServiceOverride from "@codingame/monaco-vscode-extension-gallery-service-override";
import getExtensionServiceOverride from "@codingame/monaco-vscode-extensions-service-override";
import type { IFileSystemProviderWithFileReadWriteCapability } from "@codingame/monaco-vscode-files-service-override";
import getFilesServiceOverride, {
  InMemoryFileSystemProvider,
  createIndexedDBProviders,
  registerCustomProvider,
} from "@codingame/monaco-vscode-files-service-override";
import getKeybindingsServiceOverride from "@codingame/monaco-vscode-keybindings-service-override";
import LanguageDetectionWorker from "@codingame/monaco-vscode-language-detection-worker-service-override/worker?worker";
import getLanguagesServiceOverride from "@codingame/monaco-vscode-languages-service-override";
import getLifecycleServiceOverride from "@codingame/monaco-vscode-lifecycle-service-override";
import getLogServiceOverride, {
  ConsoleLogger,
  registerAdditionalLogger,
} from "@codingame/monaco-vscode-log-service-override";
import getModelServiceOverride from "@codingame/monaco-vscode-model-service-override";
import getMultiDiffEditorServiceOverride from "@codingame/monaco-vscode-multi-diff-editor-service-override";
import getNotebookServiceOverride from "@codingame/monaco-vscode-notebook-service-override";
import getNotificationsServiceOverride from "@codingame/monaco-vscode-notifications-service-override";
import getOutlineServiceOverride from "@codingame/monaco-vscode-outline-service-override";
import getOutputService from "@codingame/monaco-vscode-output-service-override";
import OutputLinkComputerWorker from "@codingame/monaco-vscode-output-service-override/worker?worker";
import getPreferencesServiceOverride from "@codingame/monaco-vscode-preferences-service-override";
import getQuickAccessServiceOverride from "@codingame/monaco-vscode-quickaccess-service-override";
import getRelauncherServiceOverride from "@codingame/monaco-vscode-relauncher-service-override";
import getRemoteAgentServiceOverride from "@codingame/monaco-vscode-remote-agent-service-override";
import getSearchServiceOverride from "@codingame/monaco-vscode-search-service-override";
import getSecretStorageServiceOverride from "@codingame/monaco-vscode-secret-storage-service-override";
import getShareServiceOverride from "@codingame/monaco-vscode-share-service-override";
import getSnippetServiceOverride from "@codingame/monaco-vscode-snippets-service-override";
import getStorageServiceOverride from "@codingame/monaco-vscode-storage-service-override";
import getTextmateServiceOverride from "@codingame/monaco-vscode-textmate-service-override";
import TextMateWorker from "@codingame/monaco-vscode-textmate-service-override/worker?worker";
import getThemeServiceOverride from "@codingame/monaco-vscode-theme-service-override";
import getTimelineServiceOverride from "@codingame/monaco-vscode-timeline-service-override";
import getBannerServiceOverride from "@codingame/monaco-vscode-view-banner-service-override";
import getStatusBarServiceOverride from "@codingame/monaco-vscode-view-status-bar-service-override";
import getTitleBarServiceOverride from "@codingame/monaco-vscode-view-title-bar-service-override";
import getWelcomeServiceOverride from "@codingame/monaco-vscode-welcome-service-override";
import getCopyServiceOverride from "@codingame/monaco-vscode-working-copy-service-override";
import getWorkspaceTrustOverride from "@codingame/monaco-vscode-workspace-trust-service-override";
import { captureException } from "@sentry/browser";
import _debug from "debug";
import { FS_SCHEME } from "environment-interface";
import * as monaco from "monaco-editor";
import EditorWorker from "monaco-editor/esm/vs/editor/editor.worker.js?worker";
import * as vscode from "vscode";
import "vscode/localExtensionHost";
import { KeybindingsRegistry, MenuId, MenuRegistry } from "vscode/monaco";
import {
  IStorageService,
  LogLevel,
  StandaloneServices,
  initialize as initializeMonacoService,
} from "vscode/services";
import { ILifecycleService } from "vscode/vscode/vs/workbench/services/lifecycle/common/lifecycle.service";
import ExtensionHostWorker from "vscode/workers/extensionHost.worker?worker";

import { apiUrl } from "utils/csbApi";
import { getExperiments } from "utils/experiments";
import { safeLocalStorage } from "utils/safeLocalStorage";
import { executeVSCodeCommand } from "utils/vscode";

import type { MonacoConfig } from "../../../..";

import { PitcherTerminalBackend } from "./custom-services/terminal-backend";
import type { RemoteOpts } from "./remote";
import { initializeRemoteServer } from "./remote";
import { appendKeyBindings, getDefaultUserSettings } from "./settings";
import type { RegisterEditorParams } from "./tools/custom-editor";
import { createRegisterEditor } from "./tools/custom-editor";
import { kbarBindingToVSCodeBinding } from "./tools/keybindings";
import { runWithLatestMonacoConfig } from "./tools/with-monaco-config";
import { toCrossOriginWorker, toWorkerConfig } from "./tools/workers";
import { createTunnelProvider } from "./tunnel-provider";
import { disableExtension, enableExtension } from "./utils";
import * as fileSearchExtension from "./vscode-extensions/filesystem/file-search";
import { CSBFileSystemProvider } from "./vscode-extensions/filesystem/filesystem";
import { initializeGitFs } from "./vscode-extensions/filesystem/git-filesystem";
import * as gitProvider from "./vscode-extensions/git";
import * as githubExtension from "./vscode-extensions/github-auth";
import { initializeLanguages } from "./vscode-extensions/languages";
import * as liveProvider from "./vscode-extensions/live";
import * as sandboxProvider from "./vscode-extensions/sandbox";
import { initializeThemes } from "./vscode-extensions/themes";
import { WebSocketFactory } from "./websocket-factory";

import "./vscode-reset.css";

const debug = _debug("csb:vscode");

export type MonacoLibrary = typeof monaco;

const ALTERNATE_EXTENSION_DOMAINS: Record<string, string> = {
  "codesandbox.stream": "https://worker.codesandbox.stream",
  "codesandbox.io": "https://worker.codesandbox.io",
};

const monacoInitializedEmitter = new Barrier<void>();
export const onMonacoInitialized = (cb: () => void) => {
  monacoInitializedEmitter.wait().then(() => {
    cb();
  });
};

const extensionsInitializedEmitter = new Barrier<void>();
export const onExtensionsInitialized = (cb: () => void) => {
  extensionsInitializedEmitter.wait().then(() => {
    cb();
  });
};

// Monaco / VSCode is a Singleton so we vibe with it and use a singleton here as well
// to keep track of the latest pitcher and config
let lastMonacoConfig: MonacoConfig | null = null;
const monacoConfigChangeEmitter = new Emitter<MonacoConfig>();
export const onMonacoConfigChange = monacoConfigChangeEmitter.event;
export function updateMonacoConfig(config: MonacoConfig) {
  lastMonacoConfig = config;
  monacoConfigChangeEmitter.fire(config);
}

export function getLatestMonacoConfig(): MonacoConfig {
  return nullthrows(lastMonacoConfig, "Monaco has not been initialized");
}

// Workers
interface WorkerConstructor {
  new (): Worker;
}
export type WorkerLoader = () => WorkerConstructor | Promise<WorkerConstructor>;
const workerLoaders: Partial<Record<string, () => Worker>> = {
  editorWorkerService: () => new (toCrossOriginWorker(EditorWorker))(),
  textMateWorker: () => new (toCrossOriginWorker(TextMateWorker))(),
  outputLinkComputer: () =>
    new (toCrossOriginWorker(OutputLinkComputerWorker))(),
  languageDetectionWorkerService: () =>
    new (toCrossOriginWorker(LanguageDetectionWorker))(),
};
window.MonacoEnvironment = {
  getWorker: async function (moduleId, label) {
    const workerFactory = workerLoaders[label];
    if (workerFactory != null) {
      const worker = workerFactory();
      return worker;
    }
    throw new Error(`Unimplemented worker ${label} (${moduleId})`);
  },
  createTrustedTypesPolicy() {
    return undefined;
  },
};

export type Command = {
  id: string;
  name: string;
  binding?: string;
  altBinding?: string;
  macBinding?: string;
  section?: string;
  perform: (vscodeApi: typeof vscode) => void;
};

export interface VSCodeApi {
  registerKeybinding: (command: Command) => IDisposable;
  registerEditor: (params: RegisterEditorParams) => IDisposable;
  extensionApi: typeof vscode;
}

export interface LoadLibraryResult {
  monaco: MonacoLibrary;
  vscodeApi: VSCodeApi;
}

export type LoadLibraryOpts = {
  viewOpts: {
    element: HTMLElement;
  };
  remoteOpts?: RemoteOpts;
};

const REMOTE_AUTHORITY_PLACEHOLDER = "vscode.codesandbox";

async function _loadLibrary({
  viewOpts,
  remoteOpts,
}: LoadLibraryOpts): Promise<LoadLibraryResult> {
  let remoteInfo:
    | undefined
    | {
        url: string;
        token: string;
      } = undefined;

  if (remoteOpts) {
    const pitcher = getLatestMonacoConfig().pitcher;

    debug("Initializing VS Code Server");
    // We now will initialize a server for the user to connect to
    const result = await initializeRemoteServer(remoteOpts, pitcher);

    if (result.status === "running") {
      const currentId = getLatestMonacoConfig().pitcher.instanceId;
      remoteInfo = {
        token: result.token,
        // Format should be `<instanceId>-<port>.vscode.<hostname>`
        url: `${apiUrl.replace(
          "https://",
          `${currentId}-${result.port}.vscode.`,
        )}`,
      };
    } else if (result.status === "cancelled") {
      remoteOpts.onProgress({ type: "cancelled" });

      onExtensionsInitialized(async () => {
        const answer = await vscode.window.showInformationMessage(
          "Skipped VS Code Server initialization, we've opened VS Code in web-only, so only server extensions work",
          "Report Issue",
        );

        if (answer === "Report Issue") {
          window.open(
            "https://github.com/codesandbox/codesandbox-client/issues?q=is:issue+is:open+sort:updated-desc",
            "_blank",
          );
        }
      });
    } else if (result.error) {
      remoteOpts.onProgress({
        type: "error",
        error: result.error,
      });

      captureException(result.error);

      onExtensionsInitialized(async () => {
        if (
          result.error.message.includes("Dev container installation failed")
        ) {
          const answer = await vscode.window.showErrorMessage(
            "Failed to initialize VS Code Server because Dev Container installation failed. We've opened VS Code in web-only in the meantime.",
            "Open Dev Container Logs",
          );

          if (answer === "Open Dev Container Logs") {
            vscode.commands.executeCommand("codesandbox.openCsbTab", {
              type: "SETUP_TASKS",
            });
          }
        } else {
          const answer = await vscode.window.showErrorMessage(
            "Failed to initialize VS Code Server: " +
              result.error +
              ". We've opened VS Code in web-only in the meantime.",
            "Report Issue",
          );

          if (answer === "Report Issue") {
            window.open(
              "https://github.com/codesandbox/codesandbox-client/issues?q=is:issue+is:open+sort:updated-desc",
              "_blank",
            );
          }
        }
      });
    }
  }

  registerCustomProvider(FS_SCHEME, new CSBFileSystemProvider());

  const isBrowserSandboxEnvironment =
    getLatestMonacoConfig().pitcher instanceof PitcherBrowserClient;

  let filesystem: IFileSystemProviderWithFileReadWriteCapability =
    new InMemoryFileSystemProvider();

  debug("Creating IndexedDB Providers");
  try {
    const indexedDbFs = await Promise.race([
      ensureIndexedDbSafe(),
      sleep(5000).then(() => "timeout" as const),
    ]);
    if (indexedDbFs !== "timeout") {
      filesystem = indexedDbFs;
    } else {
      // eslint-disable-next-line
      console.warn("Creating IndexedDB filesystems timed out");
    }
  } catch (e) {
    // eslint-disable-next-line
    console.warn("Error creating IndexedDB filesystems", e);
  }

  const vscodeUserKeybindingsPath = vscode.Uri.from({
    scheme: "vscode-userdata",
    path: "/User/keybindings.json",
  });

  debug("Appending keybindings");
  await appendKeyBindings(filesystem, vscodeUserKeybindingsPath, []);

  const webSocketFactory = remoteInfo
    ? new WebSocketFactory(
        remoteInfo.url,
        `${REMOTE_AUTHORITY_PLACEHOLDER}-${
          getLatestMonacoConfig().pitcher.instanceId
        }`,
        remoteInfo.token,
      )
    : undefined;

  debug("Initializing preview token");
  await webSocketFactory?.initializeToken();

  registerReinitHandlers(webSocketFactory);
  const pitcherCapabilities = getLatestMonacoConfig().pitcher.capabilities;
  const VSCODE_DEV_LOG = safeLocalStorage.get("CSB/DEBUG_VSCODE") === "true";
  if (VSCODE_DEV_LOG) {
    registerAdditionalLogger(new ConsoleLogger());
  }

  let monacoOverrides: monaco.editor.IEditorOverrideServices = {
    ...getModelServiceOverride(),
    ...getDialogsServiceOverride(),
    ...getEnvironmentServiceOverride(),
    ...getConfigurationServiceOverride(),
    ...getTextmateServiceOverride(),
    ...getFilesServiceOverride(),
    ...getThemeServiceOverride(),
    ...getLanguagesServiceOverride(),
    ...getOutputService(),
    ...getWorkspaceTrustOverride(),
    ...getPreferencesServiceOverride(),
    ...getKeybindingsServiceOverride(),
    ...getSnippetServiceOverride(),
    ...getBulkEditServiceOverride(),
    ...getCopyServiceOverride(),
    ...getBannerServiceOverride(),
    ...getStorageServiceOverride({
      fallbackOverride: {},
    }),
    ...getSearchServiceOverride(),
    ...getStatusBarServiceOverride(),
    ...getCommentsServiceOverride(),
    ...getLifecycleServiceOverride(),
    ...getAccessibilityServiceOverride(),
    ...getLogServiceOverride(),
    ...getOutlineServiceOverride(),
    ...getTimelineServiceOverride(),
    ...getExtensionGalleryServiceOverride(),
    ...getAuthenticationServiceOverride(),
    ...getExtensionServiceOverride(
      toWorkerConfig(ExtensionHostWorker),
      ALTERNATE_EXTENSION_DOMAINS[window.location.hostname],
    ),
    ...getExplorerServiceOverride(),
    ...getTitleBarServiceOverride(),
    ...getQuickAccessServiceOverride({
      isKeybindingConfigurationVisible: () => true,
      shouldUseGlobalPicker: () => true,
    }),
    ...getRemoteAgentServiceOverride({ scanRemoteExtensions: true }),
    ...getNotificationsServiceOverride(),
    ...getShareServiceOverride(),
    ...getRelauncherServiceOverride(),
    ...getNotebookServiceOverride(),
    ...getEmmetServiceOverride(),
    ...getMultiDiffEditorServiceOverride(),
    ...getSecretStorageServiceOverride(),
    ...getWelcomeServiceOverride(),
    ...getChatServiceOverride(),
  };

  const lazyServiceOverrides = [];

  lazyServiceOverrides.push(
    import("./service-overrides.workbench").then((x) =>
      x.getServiceOverrides(
        ALTERNATE_EXTENSION_DOMAINS[window.location.hostname],
      ),
    ),
  );

  if (!isBrowserSandboxEnvironment) {
    lazyServiceOverrides.push(
      import("@codingame/monaco-vscode-terminal-service-override").then((x) =>
        x.default(webSocketFactory ? undefined : new PitcherTerminalBackend()),
      ),
      import("@codingame/monaco-vscode-debug-service-override").then((x) =>
        x.default(),
      ),
      import("@codingame/monaco-vscode-task-service-override").then((x) =>
        x.default(),
      ),
    );
  }

  if (pitcherCapabilities.git?.targetDiff) {
    lazyServiceOverrides.push(
      import("@codingame/monaco-vscode-scm-service-override").then((x) =>
        x.default(),
      ),
    );
  }

  debug("Loading lazy services...");
  const lazyServices = await Promise.all(lazyServiceOverrides);
  for (const service of lazyServices) {
    monacoOverrides = {
      ...monacoOverrides,
      ...service,
    };
  }
  debug("Loading lazy services done, initializing Monaco");

  const workspaceIdentifier = getWorkspaceIdentifierFromRemoteAuthority(
    webSocketFactory
      ? `${REMOTE_AUTHORITY_PLACEHOLDER}-${
          getLatestMonacoConfig().pitcher.instanceId
        }`
      : undefined,
    getLatestMonacoConfig(),
    getLatestMonacoConfig().pitcher.instanceId,
  );

  await setCustomPreferencesBeforeMonacoInit();

  await initializeMonacoService(
    monacoOverrides,
    viewOpts.element,
    {
      remoteAuthority: webSocketFactory
        ? `${REMOTE_AUTHORITY_PLACEHOLDER}-${
            getLatestMonacoConfig().pitcher.instanceId
          }`
        : undefined,
      connectionToken: remoteInfo?.token,
      productConfiguration: {
        applicationName: "codesandbox",
        nameLong: "CodeSandbox",
        nameShort: "CodeSandbox",
        extensionsGallery: {
          serviceUrl: "https://open-vsx.org/vscode/gallery",
          itemUrl: "https://open-vsx.org/vscode/item",
          resourceUrlTemplate:
            "https://open-vsx.org/vscode/unpkg/{publisher}/{name}/{version}/{path}",
          controlUrl: "",
          nlsBaseUrl: "",
          publisherUrl: "",
        },
        extensionEnabledApiProposals: {
          "GitHub.vscode-pull-request-github": [
            "activeComment",
            "commentingRangeHint",
            "commentThreadApplicability",
            "contribCommentThreadAdditionalMenu",
            "contribCommentsViewThreadMenus",
            "tokenInformation",
            "codeActionRanges",
            "commentReactor",
            "contribShareMenu",
            "fileComments",
            "contribCommentPeekContext",
            "codiconDecoration",
            "diffCommand",
            "contribCommentEditorActionsMenu",
            "treeViewMarkdownMessage",
            "tabInputTextMerge",
            "shareProvider",
            "quickDiffProvider",
          ],
        },
      },
      tunnelProvider: createTunnelProvider(getLatestMonacoConfig),
      homeIndicator: {
        icon: "code",
        title: "CodeSandbox",
        href: "https://codesandbox.io",
      },
      windowIndicator: {
        label:
          `CodeSandbox - ${
            isBrowserSandboxEnvironment ? "Sandbox" : "Devbox"
          }` + (remoteInfo ? "" : " (Web)"),
        tooltip: remoteInfo
          ? "VSCode is connected to a microVM, so you can install server extensions"
          : "VSCode is running in the browser, so you can only install web extensions",
      },
      developmentOptions: {
        logLevel: VSCODE_DEV_LOG ? LogLevel.Trace : LogLevel.Warning,
      },
      configurationDefaults: {
        ...getDefaultUserSettings(isBrowserSandboxEnvironment),
        "window.commandCenter": false,
        "workbench.layoutControl.enabled": false,
        "workbench.activity.showAccounts": false,
        "workbench.editor.autoLockGroups": {
          "codesandbox.editors.codesandbox-tab": true,
          "codesandbox.editors.codesandbox-new-tab": true,
        },
        "terminal.integrated.defaultProfile.linux": "zsh",
        "git.autofetch": true,
        "git.enableSmartCommit": true,
        "[typescript]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
        "[typescriptreact]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
        "[javascript]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
        "[javascriptreact]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
        "[css]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
        "[html]": {
          "editor.defaultFormatter": "esbenp.prettier-vscode",
        },
      },

      webSocketFactory,
      resourceUriProvider: webSocketFactory
        ? (uri) => {
            return webSocketFactory.rewriteUri(uri);
          }
        : undefined,

      defaultLayout: setDefaultLayout(
        getLatestMonacoConfig().pitcher,
        workspaceIdentifier.folderUri,
      ),
      workspaceProvider: {
        trusted: true,

        async open() {
          return false;
        },

        workspace: workspaceIdentifier,
      },

      resolveExternalUri: async (uri) => {
        if (
          (uri.scheme === "http" || uri.scheme === "ws") &&
          uri.authority.includes("localhost")
        ) {
          const [_, port] = uri.authority.split(":");

          const ports = getLatestMonacoConfig().pitcher.clients.port.getPorts();
          const matchingPort = ports.find((p) => p.port === parseInt(port, 10));

          if (!matchingPort) {
            return uri;
          }

          return uri.with({
            authority: matchingPort.url,
            scheme: uri.scheme + "s", // http -> https, ws -> wss
          });
        }

        return uri;
      },
    },
    {
      userHome: monaco.Uri.from({
        scheme: FS_SCHEME,
        path: webSocketFactory
          ? "/root"
          : `/project/home/${
              getLatestMonacoConfig().pitcher.currentClient.username
            }`,
      }),
    },
  );

  monacoInitializedEmitter.open();

  debug("Initialized Monaco, initializing themes...");
  initializeThemes();

  // Load the GitHub auth extension ASAP, because the GitHub PR extension relies on this
  await githubExtension.initializeExtension();

  debug("Initializing codesandbox extension...");

  const extension = await import(
    "./vscode-extensions/codesandbox/workbench/extension"
  );
  const extensionApi = await extension.createDefaultApi();
  runWithLatestMonacoConfig((config) =>
    extension.activate(
      config.pitcher,
      Boolean(webSocketFactory),
      isBrowserSandboxEnvironment,
    ),
  );

  debug("Initializing default extensions...");
  await import("./vscode-extensions/default-extensions");

  disableExtension("vscode.simple-browser");
  if (remoteInfo) {
    if (!pitcherCapabilities.git?.targetDiff) {
      disableExtension("GitHub.vscode-pull-request-github");
    }

    if (remoteOpts?.isProtected || !pitcherCapabilities.git?.targetDiff) {
      disableExtension("vscode.git");
    } else {
      enableExtension("vscode.git");
    }
  } else {
    debug("Loading languages, file search and extra extensions...");
    await import("./vscode-extensions/default-extensions.extras");
    initializeLanguages();

    await Promise.all([
      fileSearchExtension.activate(),
      import("./vscode-extensions/default-extensions"),
      initializeGitFs(),
    ]);

    if (pitcherCapabilities.git?.targetDiff) {
      gitProvider.activate();
    }
  }

  if (pitcherCapabilities.channel) {
    liveProvider.activate();
  }

  if (isBrowserSandboxEnvironment) {
    runWithLatestMonacoConfig((config) =>
      sandboxProvider.activate(config.pitcher),
    );
  }

  const registerKeybinding = (command: Command) => {
    const disposableStore = new DisposableStore();
    disposableStore.add(
      KeybindingsRegistry.registerCommandAndKeybindingRule({
        id: command.id,
        weight: 100,
        when: undefined,
        primary: command.binding
          ? kbarBindingToVSCodeBinding(command.binding)
          : undefined,

        secondary: command.altBinding
          ? [kbarBindingToVSCodeBinding(command.altBinding)]
          : undefined,
        mac: command.macBinding
          ? {
              primary: kbarBindingToVSCodeBinding(command.macBinding),
            }
          : undefined,
        handler: () => {
          command.perform(vscode);
        },
      }),
    );

    disposableStore.add(
      MenuRegistry.appendMenuItem(MenuId.CommandPalette, {
        command: {
          id: command.id,
          title: command.name,
          category: command.section,
        },
      }),
    );

    return disposableStore;
  };

  if (isBrowserSandboxEnvironment) {
    // If we're in a browser environment, we use the Prettier extension for our formatting needs.
    // We use a custom version that supports basic filesystem configuration and formatting of css files:
    // https://github.com/codesandbox/prettier-vscode/pull/1
    import(
      // @ts-ignore doesn't understand the paths
      "./vscode-extensions/builtin-extensions/prettier-vscode-10.1.0.vsix"
    );

    // Also set the right evaluation strategy based on the user's settings
    const pitcherClient = getLatestMonacoConfig()
      .pitcher as PitcherBrowserClient;

    pitcherClient.sandpack.setEvaluationStrategy(
      extensionApi.workspace
        .getConfiguration("sandbox")
        .get("previewRunAction") || "edit-delayed",
    );

    extensionApi.workspace.onDidChangeConfiguration((evt) => {
      if (evt.affectsConfiguration("sandbox.previewRunAction")) {
        pitcherClient.sandpack.setEvaluationStrategy(
          extensionApi.workspace
            .getConfiguration("sandbox")
            .get("previewRunAction") || "edit-delayed",
        );
      }
    });
  }

  extensionsInitializedEmitter.open();

  const lsService = StandaloneServices.get(ILifecycleService);
  lsService.onBeforeShutdown(() => {
    // @ts-expect-error this is internal
    window.$CSB_SHUTTING_DOWN = true;
  });

  debug("Loaded!");

  return {
    monaco,

    vscodeApi: {
      registerKeybinding,
      registerEditor: await createRegisterEditor(),
      extensionApi,
    },
  };
}

const getStatusBarTooltip = async (): Promise<vscode.StatusBarItem> => {
  await monacoInitializedEmitter.wait();
  const item = vscode.window.createStatusBarItem(
    "codesandbox.remote-available-indicator",
    1,
    10000,
  );
  item.text = "$(extensions) Enable VSCode Extensions";
  item.color = "#AC9CFF";
  item.tooltip =
    "You now have write access, which means you can install VSCode extensions by connecting to VSCode Server! Click this button to refresh and enable VSCode extensions.";
  item.command = "workbench.action.reloadWindow";

  return item;
};

function registerReinitHandlers(
  webSocketFactory: WebSocketFactory | undefined,
) {
  const statusbarTooltipPromise = getStatusBarTooltip();

  let isFirstInitialize = true;
  let lastInstanceId = getLatestMonacoConfig().pitcher.instanceId;
  runWithLatestMonacoConfig((config) => {
    const reinit = (instanceId: string) => {
      if (
        config.pitcher.capabilities.shell?.io &&
        config.pitcher.permissions.shell?.create &&
        !webSocketFactory &&
        getExperiments().serverExtensions
      ) {
        // This means that we're in a browser environment, but we now support VSCode server!
        // We can tell the user to refresh the browser to get the new instance.
        statusbarTooltipPromise.then((s) => s.show());
      } else {
        statusbarTooltipPromise.then((s) => s.hide());
      }

      if (webSocketFactory) {
        debug("reinitializing websocket hostname", {
          from: webSocketFactory.currentHostname,
          to: webSocketFactory.currentHostname.replace(
            lastInstanceId,
            instanceId,
          ),
        });
        // When the instance has changed, trigger a reconnect to force VSCode to connect to the new instance,
        // and also to resume socket again.
        webSocketFactory.replaceHostname(
          webSocketFactory.currentHostname.replace(lastInstanceId, instanceId),
        );
        lastInstanceId = instanceId;
        debug("triggering vscode server reconnect");
        executeVSCodeCommand("workbench.action.triggerReconnect");

        setTimeout(() => {
          // We missed some fs events in the meantime, and git extension did not get the new
          // status (of a new branch, for example). So we trigger a manual refresh.
          executeVSCodeCommand("git.refresh");
        }, 1000);
      }

      const workspaceIdentifier = getWorkspaceIdentifierFromRemoteAuthority(
        webSocketFactory
          ? `${REMOTE_AUTHORITY_PLACEHOLDER}-${
              getLatestMonacoConfig().pitcher.instanceId
            }`
          : undefined,
        config,
        instanceId,
      );
      const storageService = StandaloneServices.get(IStorageService);
      // The following id generation is copied from the VSCode codebase, we use it to stay consistent
      // with VSCode.
      const id = createHash("md5")
        .update(workspaceIdentifier.folderUri.toString())
        .digest("hex");
      const workspaceIdentifierWithId = {
        ...workspaceIdentifier,
        id,
      };
      storageService.switch(workspaceIdentifierWithId, true);
    };

    // Make sure we don't initialize the monaco service when loading for the first time
    if (isFirstInitialize) {
      isFirstInitialize = false;
    } else {
      reinit(config.pitcher.instanceId);
    }

    const disposableStore = new DisposableStore();

    if (webSocketFactory) {
      let changingInstance = false;
      // When the instance starts to change, we want to stop VSCode from sending any message to
      // ensure that the client doesn't get confused with an old state.
      disposableStore.add(
        config.pitcher.onInstanceChangeRequired(() => {
          debug("triggering vscode server socket pause");
          changingInstance = true;
          executeVSCodeCommand("workbench.action.pauseSocketWriting");
        }),
      );

      const dispose = config.pitcher.onStateChange((evt) => {
        if (changingInstance) {
          // Don't reconnect while we're changing the instance

          return;
        }

        if (evt.state === "HIBERNATED") {
          debug("hibernated, will tell VSCode not to try connecting");
          executeVSCodeCommand("workbench.action.pauseSocketWriting");
        } else if (evt.state === "CONNECTING" || evt.state === "CONNECTED") {
          debug("connecting/connected, will tell VSCode to try connecting");
          executeVSCodeCommand("workbench.action.triggerReconnect");
        }
      });
      disposableStore.add({ dispose });

      disposableStore.add(
        config.pitcher.onInstanceChanged(({ instanceId }) => {
          changingInstance = false;
          debug("triggering vscode server socket pause");
          reinit(instanceId);
        }),
      );

      disposableStore.add({
        dispose() {
          // When getting a new monaco, pause the socket
          executeVSCodeCommand("workbench.action.pauseSocketWriting");
        },
      });
    }

    return disposableStore;
  });
}

function getWorkspaceIdentifierFromRemoteAuthority(
  remoteAuthority: string | undefined,
  latestMonacoConfig: MonacoConfig,
  instanceId: string,
) {
  return remoteAuthority
    ? {
        folderUri: monaco.Uri.from({
          scheme: "vscode-remote",
          path: latestMonacoConfig.pitcher.userWorkspacePath,
          authority: remoteAuthority,
        }),
      }
    : {
        folderUri: monaco.Uri.from({
          scheme: FS_SCHEME,
          path: latestMonacoConfig.pitcher.workspacePath,
          authority: instanceId,
        }),
      };
}

async function ensureIndexedDbSafe() {
  const indexedDbVersion = safeLocalStorage.get("monaco-indexeddb-version");
  if (!indexedDbVersion) {
    // monaco-vscode-api sometimes creates some incompatible upgrades to indexeddb.
    // For example, it can add new stores to the db, which will cause an error when loading
    // with the new version.
    // With this change, we ensure that we clear the DB before loading monaco-vscode-api.
    const databases = await indexedDB.databases();
    if (databases.some((d) => d.name === "vscode-web-db")) {
      await new Promise<void>((r, e) => {
        debug(
          "Deleting vscode-web-db indexedDB because it's on an older version...",
        );
        const dbTransaction = indexedDB.deleteDatabase("vscode-web-db");
        dbTransaction.onsuccess = () => r();
        dbTransaction.onerror = () => e(dbTransaction.error);
      });
      debug("IndexedDB deletion succeeded");
    }
    safeLocalStorage.set("monaco-indexeddb-version", "1");
  }

  return await createIndexedDBProviders();
}

let libraryPromise: Promise<LoadLibraryResult> | null = null;
export function loadMonacoLibrary(
  opts: LoadLibraryOpts,
): Promise<LoadLibraryResult> {
  if (!lastMonacoConfig) {
    throw new Error(
      "Need to call updateMonacoConfig(config) before calling this function",
    );
  }

  if (!libraryPromise) {
    libraryPromise = _loadLibrary(opts);
  }
  return libraryPromise;
}

function setCustomPreferencesBeforeMonacoInit() {
  const STORAGE_KEY = `vscode-web-state-db-global`;
  const STORAGE_OBJECT_STORE = "ItemTable";
  const OBJECT_KEY = "workbench.activity.pinnedViewlets2";

  return new Promise((resolve) => {
    if (!window.indexedDB) {
      resolve(null);

      return;
    }

    // Open the database
    const request = window.indexedDB.open(STORAGE_KEY);

    request.onupgradeneeded = function () {
      try {
        const db = request.result;
        if (!db.objectStoreNames.contains(STORAGE_OBJECT_STORE)) {
          db.createObjectStore(STORAGE_OBJECT_STORE);
        }
      } catch {
        resolve(null);
      }
    };

    request.onerror = function () {
      resolve(null);
    };

    request.onsuccess = function () {
      const db = request.result;
      const transaction = db.transaction(STORAGE_OBJECT_STORE, "readwrite");
      const store = transaction.objectStore(STORAGE_OBJECT_STORE);
      const getRequest = store.get(OBJECT_KEY);

      transaction.onerror = function () {
        resolve(null);
      };

      getRequest.onerror = function () {
        resolve(null);
      };

      getRequest.onsuccess = function () {
        // Key already exists
        if (getRequest.result) {
          resolve(null);
        } else {
          // Add new custom preference
          store.add(CUSTOM_SIDEBAR_ORDER, OBJECT_KEY);
          resolve(null);
        }
      };
    };
  });
}

const CUSTOM_SIDEBAR_ORDER = JSON.stringify([
  {
    id: "workbench.view.explorer",
    pinned: true,
    order: 0,
  },
  {
    id: "workbench.view.search",
    pinned: true,
    order: 1,
  },
  {
    id: "workbench.view.extension.codesandbox",
    pinned: true,
    order: 10,
  },
  {
    id: "workbench.view.scm",
    pinned: true,
    order: 2,
  },
  {
    id: "workbench.view.debug",
    pinned: true,
    order: 3,
  },
  {
    id: "workbench.view.remote",
    pinned: true,
    order: 4,
  },
  {
    id: "workbench.view.extensions",
    pinned: true,
    order: 4,
  },
  {
    id: "workbench.view.extension.references-view",
    pinned: true,
    order: 7,
  },
  {
    id: "workbench.view.extension.github-pull-requests",
    pinned: true,
    order: 8,
  },
  {
    id: "workbench.view.extension.github-pull-request",
    pinned: true,
    order: 9,
  },
  {
    id: "workbench.panel.chatSidebar",
    pinned: true,
    order: 100,
  },
]);

function setDefaultLayout(pitcher: IPitcherClient, folderUri: monaco.Uri) {
  const DEFAULT_FILES_TO_OPEN = [
    "/README.md",
    "/src/App.js",
    "/src/App.tsx",
    "/src/index.js",
    "/src/index.ts",
    "/src/index.tsx",
    "/index.js",
    "/index.ts",
    "/src/main.tsx",
    "/src/main.jsx",
    "/main.ts",
    "/src/main.rs",
    "/src/lib.rs",
    "/index.html",
    "/src/index.html",
    "/src/index.vue",
    "/src/App.vue",
    "/src/main.astro",
    "/package.json",
  ];

  let mainFile: string | null = null;
  for (const file of DEFAULT_FILES_TO_OPEN) {
    if (pitcher.clients.fs.getIdFromPath(file)) {
      mainFile = file;

      break;
    }
  }

  // default csb tab
  const csbNewTab = monaco.Uri.from({
    scheme: "csb-new-tab",
    path: "/",
  });

  const fileEditor = mainFile
    ? folderUri.with({ path: join(folderUri.path, mainFile) })
    : csbNewTab;

  return {
    editors: [
      {
        uri: fileEditor,
        viewColumn: 1,
      },
      {
        uri: csbNewTab,
        options: {
          sticky: true,
          pinned: true,
          inactive: true,
          preserveFocus: true,
        },
        viewColumn: 2,
      },
    ],
    layout: {
      editors: {
        orientation: 0,
        groups: [{ size: 1 }, { size: 1 }],
      },
    },
  };
}
